<template>
    <v-container fill-height fluid :class="isLoginProgressing && 'fade'">
        <v-row>
            <v-col align="center">
                <div v-if="isLoginProgressing" class="circular">
                    <progress-circular :text="''" />
                </div>
                <login-form />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import LoginFormComponent from '@/components/Auth/organisms/LoginForm.vue';
import ProgressCircularComponent from '@/components/Common/molecules/ProgressCircular.vue';
import { provideLoginForm } from '@/composables/store/Auth/useLoginForm';
import { useLoginFormProgressCircular } from '@/composables/store/Auth/useLoginFormProgressCircular';

export default defineComponent({
    components: {
        LoginForm: LoginFormComponent,
        ProgressCircular: ProgressCircularComponent,
    },
    setup() {
        // 依存性なし
        provideLoginForm();
        const { isLoginProgressing } = useLoginFormProgressCircular();

        return {
            isLoginProgressing,
        };
    },
});
</script>
<style lang="scss" scoped>
.fade {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.5;
}
.circular {
    position: absolute;
    top: 40%;
    left: 46%;
    z-index: 1;
}
</style>

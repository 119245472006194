import { IAuthRepository, LoginRequestResponse } from '@/models/interface/Auth/IAuthRepository';
import { inject, injectable } from 'tsyringe';

@injectable()
export class LoginService {
    constructor(@inject('IAuthRepository') private readonly repository: IAuthRepository) {}

    async handle(loginId: string, password: string): Promise<LoginRequestResponse> {
        return this.repository.postLogin(loginId, password);
    }
}

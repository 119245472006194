// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useFormValidation = () => {
    const loginFormRules = {
        loginId: [(v: string) => !!v || 'ログインIdを入力してください。'],
        password: [(v: string) => !!v || 'パスワードを入力してください。'],
    };

    return {
        loginFormRules,
    };
};

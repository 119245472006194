







































import { useFormValidation } from '@/composables/function/Auth/useFormValidation';
import { useAuthenticationInfo } from '@/composables/store/Auth/useAuthenticationInfo';
import { useLoginForm } from '@/composables/store/Auth/useLoginForm';
import { useRouter } from '@/composables/function/Common/useRouter';
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
    setup() {
        const router = useRouter();
        const valid = ref(false);
        const showPassword = ref(false);

        const { loginForm, error, submit } = useLoginForm();
        const { loginFormRules } = useFormValidation();
        const { authenticatedUser } = useAuthenticationInfo();

        const login = async (): Promise<void> => {
            await submit();
            if (authenticatedUser()) {
                router.push('/management');
            }
        };

        return {
            valid,
            showPassword,
            loginForm,
            error,
            loginFormRules,
            login,
        };
    },
});

import { isFailedResponse } from '@/models/interface/common';
import { LoginService } from '@/models/service/Auth/LoginService';
import { ErrorStatus } from '@/types/common';
import { inject, provide, Ref, ref } from '@vue/composition-api';
import { container } from 'tsyringe';
import { useAuthenticationInfo } from './useAuthenticationInfo';
import { useLoginFormProgressCircular } from './useLoginFormProgressCircular';

type LoginForm = {
    loginId: string;
    password: string;
};

const loginService = container.resolve(LoginService);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const provideLoginForm = () => {
    const error = ref<ErrorStatus>({
        hasError: false,
        message: '',
    });

    const loginForm = ref<LoginForm>({ loginId: '', password: '' });

    provide('error', error);
    provide('loginForm', loginForm);
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
export const useLoginForm = () => {
    const error = inject('error') as Ref<ErrorStatus>;
    const loginForm = inject('loginForm') as Ref<LoginForm>;

    const { setAuthenticationInfo } = useAuthenticationInfo();
    const { loginProgressStart, loginProgressEnd } = useLoginFormProgressCircular();

    const submit = async (): Promise<void> => {
        loginProgressStart();
        const result = await loginService.handle(loginForm.value.loginId, loginForm.value.password);

        if (isFailedResponse(result)) {
            error.value.hasError = true;
            error.value.message = result.data.data.message;
            loginProgressEnd();
            return;
        }

        setAuthenticationInfo(true, result.data.data);
        error.value.hasError = false;
        error.value.message = '';
        loginProgressEnd();
    };

    return {
        loginForm,
        error,
        submit,
    };
};
